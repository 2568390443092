
import IconBase from '~/components/icons/IconBase'

export default {
  extends: IconBase,
  props: {
    width: {
      type: [Number],
      default: 589.1,
    },
    height: {
      type: [Number],
      default: 382.63,
    },
  },
}
