
export default {
  name: 'IconBase',
  props: {
    width: {
      type: [Number],
      default: 1,
    },
    height: {
      type: [Number],
      default: 1,
    },
  },
  data() {
    return {
      ratio: this.width / this.height,
    }
  },
  computed: {
    widthEm() {
      return `${this.ratio}em`
    },
    heightEm() {
      return `1em`
    },
  },
}
